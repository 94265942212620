import { getMD5 } from '@/utils/string-tool'
export const ADD_MSG = 'ADD_MSG'
export const INSERT_MSGS = 'INSERT_MSGS'
export const UPDATE_MSGS_STATUS = 'UPDATE_MSGS_STATUS'
export const CLEAR_MSGS = 'CLEAR_MSGS'
export const DELETE_CHAT_MSGS = 'DELETE_CHAT_MSGS'
export const DELETE_MSG = 'DELETE_MSG'
export const SET_MSG_META = 'SET_MSG_META'
export const UPDATE_MSG_CONTENT = 'UPDATE_MSG_CONTENT'

// status: PropTypes.oneOf(['sending', 'sendFailed', 'isSend', 'isRead']),

export function updateMsgContent(chatid, messageId, msgContent) {
  return {
    type: UPDATE_MSG_CONTENT,
    payload: {
      chatid,
      messageId,
      msgContent
    }
  }
}

export function addMsg(uid, chatid, msgType, msgContent, msgMeta) {
  let ts = new Date().getTime()
  if (msgMeta && msgMeta.is_gpt) {
    ts = ts + 500
  }
  return {
    type: ADD_MSG,
    payload: {
      id: getMD5(uid + chatid + ts + Math.random()),
      chatid,
      uid,
      msgType,
      msgContent,
      msgMeta,
      msgStatus: 'sending',
      ts,    
    },
  }
}

export function insertMsgs(messages) {
  const msgs = messages.map((e) => {
    let msg_status = 'isSend'
    if (e.is_read) {
      msg_status = 'isRead'
    }    
    return {
      id: e.id,
      chatid: e.chatid,
      uid: e.uid,
      msgType: e.msg_type,
      msgContent: e.msg_content,
      msgMeta: e.msg_meta,
      msgStatus: msg_status,      
      ts: e.ts,
    }
  })  
  return {
    type: INSERT_MSGS,
    payload: msgs ,
  }
}

export function updateMsgsStatus(chatid, messageIds, status) {
  return {
    type: UPDATE_MSGS_STATUS,
    payload: {
      chatid,
      messageIds,
      status
    }
  }
}

export function clearMsgs(chatids) {
  return {
    type: CLEAR_MSGS,
    payload: {chatids}
  }
}

export function deleteChatMsgs(chatid) {
  return {
    type: DELETE_CHAT_MSGS,
    payload: {chatid}
  }
}

export function deleteMsg(chatid, messageId) {
  return {
    type: DELETE_MSG,
    payload: {chatid, messageId}
  }
}

export function setMsgMeta(chatid, messageId, metaData) {
  return {
    type: SET_MSG_META,
    payload: {chatid, messageId, metaData}
  }
}



