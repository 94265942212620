import { request, post } from './http'

export function uploadFileApi(func, file, chatid) {
  return request('post', '/v1/base/upload_file', {func, file, chatid}, { 'Content-Type': 'multipart/form-data' })
}

// 'event': record['event'],
// 'page': record['page'],
// 'widget': record.get('widget'),
// 'extra': record.get('extra', {}),
export function trackApi(records) {
  return post('/v1/base/track', {records})
}

export function getVerifyCodeApi() {
  return post('/v1/base/get_verify_code', {})
}

export function getRealUrlApi(browser_type) {
  return post('/v1/base/get_real_url', {browser_type})
}