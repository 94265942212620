import { post } from './http'
import axios from 'axios'

export function loadMoreChatsApi(from_id, limit) {
  return post('/v1/anonchat/load_more_chats', {from_id, limit})
}

export function loadMoreMessagesApi(chatid, from_id, limit, is_group_chat, is_debug) {
  return post('/v1/anonchat/load_more_messages', {chatid, from_id, limit, is_group_chat, is_debug})
}

export function deleteChatApi(chatid, is_group_chat) {
  return post('/v1/anonchat/delete_chat', {chatid, is_group_chat})
}

export function updateChatApi(chatId, isCollect) {
  return post('/v1/anonchat/update_chat', {chat_id: chatId, is_collect: isCollect})
}

export function clearChatsApi() {
  return post('/v1/anonchat/clear_chats', {})
}

export function getChatApi(chat_id, is_group_chat) {
  return post('/v1/anonchat/get_chat', {chat_id, is_group_chat})
}

export function getChatInfoApi(chatid, is_group_chat) {
  return post('/v1/anonchat/get_chat_info', {chatid, is_group_chat})
}

export function startChatApi(from, token, password) {
  return post('/v1/anonchat/start_chat', {from, token, password})
}

export function getRandomTopic() {
  return post('/v1/anonchat/get_random_topic', {})
}

export function saveBottleApi(content, max_pv) {
  return post('/v1/anonchat/save_bottle', {content, max_pv})
}

export function getBottleApi(settings) {
  return post('/v1/anonchat/get_bottle', {settings})
}

export function reportBottleApi(bottle_id) {
  return post('/v1/anonchat/report_bottle', {bottle_id})
}

export function loadNewMessagesApi(last_message_id, last_message_ts) {
  return post('/v1/anonchat/load_new_messages', {last_message_id, last_message_ts})
}

export function pickOnlineUserApi(settings) {
  return post('/v1/anonchat/pick_online_user', {settings})
}

export function createGroupChatApi(chat_title, max_member_count, password) {
  return post('/v1/anonchat/create_group_chat', {chat_title, max_member_count, password})
}

export function getGroupChatEditInfoApi(group_id, is_manager) {
  return post('/v1/anonchat/get_group_chat_edit_info', {group_id, is_manager})
}

export function updateGroupChatApi(group_id, chat_title, max_member_count, password) {
  return post('/v1/anonchat/update_group_chat', {group_id, chat_title, max_member_count, password})
}

export function generateGroupChatUrlApi(group_id) {
  return post('/v1/anonchat/generate_group_chat_url', {group_id})
}

export function deleteGroupChatUrlApi(group_id) {
  return post('/v1/anonchat/delete_group_chat_url', {group_id})
}

export function getGroupChatInfoApi(group_token) {
  return post('/v1/anonchat/get_group_chat_info', {group_token})
}

export function getGroupChatUsersInfoApi(group_id) {
  return post('/v1/anonchat/get_group_chat_users_info', {group_id})
}

export function deleteGroupChatMemberApi(group_id, member_id) {
  return post('/v1/anonchat/delete_group_chat_member', {group_id, member_id})
}

export function checkCanUploadFileApi(to_uid, func, is_group_chat, chatid) {
  return post('/v1/anonchat/check_can_upload_file', {to_uid, func, is_group_chat, chatid})
}

export function clearChatMsgsApi(chatid, is_group_chat) {
  return post('/v1/anonchat/clear_chat_msgs', {chatid, is_group_chat})
}

export function getDuanziApi() {
  return post('/v1/anonchat/get_duanzi', {})
}

export function getFindlostListApi() {
  return post('/v1/anonchat/get_findlost_list', {})
}

export function saveFindlostApi(title, content, question, answer, contack_with) {
  return post('/v1/anonchat/save_findlost', {title, content, question, answer, contack_with})
}

export function confirmFindlostApi(findlostId, myAnswer) {
  return post('/v1/anonchat/confirm_findlost', {findlostId, myAnswer})
}

export function checkSaveFindlostApi() {
  return post('/v1/anonchat/check_save_findlost', {})
}



export async function gptChatStreamApi({robotId, chatid, userMsg, newMessageId}, onData, onEnd, onError) {
    const response = await axios.post('/api/v1/gpt/chat', {robotId, chatid, userMsg, newMessageId}, {
        headers: {
            'Content-Type': 'application/json',
        },
        responseType: 'stream', // 设置为流式响应
    });

    response.data.on('data', (chunk) => {
      // 处理每一段流数据
      const data = chunk.toString();
      // console.log('Received chunk:', data);
      onData(data)
    });

    response.data.on('end', () => {
        console.log('Stream ended.');
        onEnd()
    });

    response.data.on('error', (err) => {
        console.error('Stream error:', err);
        onError(err)
    });

    // response.data.on('data', (chunk) => {
    //     // 处理每一段流数据
    //     const data = chunk.toString();
    //     console.log('Received chunk:', data);
    // });

    // response.data.on('end', () => {
    //     console.log('Stream ended.');
    // });

    // response.data.on('error', (err) => {
    //     console.error('Stream error:', err);
    // });
}