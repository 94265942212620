import { SET_USER, UPDATE_USER } from '../actions/user'

const initialState = {
  id: null,
  nickname: '',
  avatar: null,
  gender: 'keep_secret',
  age: 0,
  location: null,
  show_location: false,
  account: null,
  status: null,
  notify_sound: true,
  allow_picked: true,
  likes: null,
}

const userReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_USER: {
      return Object.assign({}, state, payload)
    }
    
    case UPDATE_USER: {
      return Object.assign({}, state, payload)
    }

    default:
      return state
  }
}

export default userReducer
