import { LOAD_MORE, SET_LAST_MSG, ADD_CHAT, CHANGE_UNREAD_MSG_COUNT, DELETE_CHAT, CLEAR_CHATS,UPDATE_CHAT, SET_GROUP_UIDS, OTHER_DELETE_CHAT } from "../actions/chatMap"

const initialState = {
  // 'c1': {
  //   id: '1',
  //   chatid: 'c1',
  //   uid: '',
  //   toUid: '',
  //   isGroupChat: true,
  //   avatar: '',
  //   title: '英语学习',
  //   status: null,
  //   lastMsgType: 'text',
  //   lastMsgContent: '你好呀',
  //   lastMsgDate: 1661207788000,
  //   unreadMsgCount: 1,
  //   createTs: 1661207788000,
  // }, 
  // 'c2': {
  //   id: '2',
  //   chatid: 'c2',
  //   uid: '',
  //   toUid: '',
  //   isGroupChat: false,
  //   avatar: '',
  //   title: 'Jack',
  //   status: 'online',
  //   lastMsgType: 'text',
  //   lastMsgContent: '你好呀',
  //   lastMsgDate: 1671208788000,
  //   unreadMsgCount: 1,
  //   createTs: 1661207788000,
  // }, 
}

const chatMapReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_MORE: {
      const chats = payload
      chats.forEach(e => {
        const chatid = e.chatid
        state[chatid] = {
          id: e.id,
          chatid: e.chatid,
          uid: e.uid,
          toUid: e.to_uid,
          isGroupChat: e.is_group_chat || false,
          avatarColor: e.avatar_color || '#e63470',
          memberUids: {},
          onlineUids: {},
          avatar: e.avatar,
          title: e.title,
          status: e.status,
          lastMsgType: e.last_msg_type,
          lastMsgContent: e.last_msg_content,
          lastMsgDate: e.last_msg_date,
          unreadMsgCount: e.unread_msg_count || 0,
          createTs: e.create_ts,
          isCollect: e.is_collect,
          isBlock: e.is_block,
          banType: e.ban_type,
          otherLeave: e.other_leave,
          isGpt: e.is_gpt,
        }
      });
      return Object.assign({}, state)
    }

    case SET_LAST_MSG: {
      const {chatid, lastMsgType, lastMsgContent, lastMsgDate} = payload
      if (state[chatid]) {
        if (state[chatid].lastMsgDate > lastMsgDate) {
          return state
        }
        state[chatid].lastMsgType = lastMsgType
        state[chatid].lastMsgContent = lastMsgContent
        state[chatid].lastMsgDate = lastMsgDate            
      }
      return Object.assign({}, state)
    }

    case ADD_CHAT: {   
      const {chatid} = payload
      const e = payload
      state[chatid] = {
        id: e.id,
        chatid: e.chatid,
        uid: e.uid,
        toUid: e.to_uid,
        isGroupChat: e.is_group_chat || false,
        avatar: e.avatar,
        avatarColor: e.avatar_color || '#e63470',
        onlineUids: {},
        memberUids: {},
        title: e.title,
        status: e.status,          
        lastMsgType: e.last_msg_type || null,
        lastMsgContent: e.last_msg_content  || null,
        lastMsgDate: e.last_msg_date || null,
        unreadMsgCount: e.unread_msg_count  || 0,
        createTs: e.create_ts,
        isCollect: e.is_collect,
        isBlock: e.is_block,
        banType: e.ban_type,
        otherLeave: e.other_leave,
        isGpt: e.is_gpt,
      }
      return Object.assign({}, state)
    } 

    case CHANGE_UNREAD_MSG_COUNT: {
      const {chatid, count, isCover} = payload
      if (state[chatid]){
        if (isCover) {
          state[chatid].unreadMsgCount = count
        } else {
          state[chatid].unreadMsgCount = state[chatid].unreadMsgCount + count
        }      
        return Object.assign({}, state)
      }
      return state
    }  

    case DELETE_CHAT: {   
      const {chatid} = payload
      if (state[chatid]){
        delete state[chatid]
      }
      return Object.assign({}, state)
    }
    
    case OTHER_DELETE_CHAT: {
      const {chatid} = payload
      if (state[chatid]){
        Object.assign(state[chatid], {
          otherLeave: 1,
          lastMsgType: null,
          lastMsgContent: null,
          lastMsgDate: null,
          unreadMsgCount: 0,
        })
        console.log('OTHER_DELETE_CHAT', state[chatid])
      }
      return Object.assign({}, state)
    }

    case UPDATE_CHAT: {   
      const {chatid, data} = payload
      if (state[chatid]){
        Object.assign(state[chatid], data)
      }
      return Object.assign({}, state)
    }
    
    case CLEAR_CHATS: {   
      const {chatids} = payload
      chatids.forEach(chatid => {
        if (state[chatid]){
          delete state[chatid]
        }
      });
      return Object.assign({}, state)
    }

    case SET_GROUP_UIDS: {   
      const {chatid, uids, opt, uidType} = payload

      let key = 'onlineUids'
      if (uidType !== 'online') key = 'memberUids'
      if (!state[chatid]) {
        state[chatid] = {}
      }
      if (!state[chatid][key]) {
        state[chatid][key] = {}
      }
      uids.forEach(uid => {
        if (opt === 'add') {
          state[chatid][key][uid] = 1
        } else if (opt === 'remove') {
          if (state[chatid][key][uid]){
            delete state[chatid][key][uid]
          }
        }
      });
      return Object.assign({}, state)
    }

    default:
      return state
  }
}

export default chatMapReducer
