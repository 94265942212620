import { combineReducers } from 'redux'
import appReducer from './app'
import userReducer from './user'
import chatMapReducer from './chatMap'
import messageReducer from './messageMap'
import userMapReducer from './userMap'



const allReducers = {
  app: appReducer,
  user: userReducer,
  chatMap: chatMapReducer,
  messageMap: messageReducer,
  userMap: userMapReducer,
}

const rootReducer = combineReducers(allReducers)

export default rootReducer
