import { getOnlineUserMapApi, getNewUserChartApi, setForceActiveApi } from '@/services/crm'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button, Switch, Toast } from 'antd-mobile'

export default function CrmPage() {
  const chartRef1 = useRef(null)
  const chartRef2 = useRef(null)
  const chartRef3 = useRef(null)
  const chartRef4 = useRef(null)
  const chartRef5 = useRef(null)
  const chartRef6 = useRef(null)
  const navigate = useNavigate()
  const [forceActive, setForceActive] = useState(false)

  function getOnlineUserMap(data) {
    setForceActive(data.force_active)

    let total1 = 0
    data.data['user_map'].forEach((e) => {
      total1 = total1 + e.value
    })
    var option1 = {
      title: {
        text: '在线总数: ' + total1 + '人',
        subtext: data.ts,
        left: 'left',
      },
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data.data['user_map'],
          label: {
            show: true,
            formatter: (arg) => {
              return arg.data.name + ' ' + arg.data.value + '人 ' + arg.percent + '%'
            },
          },
        },
      ],
    }

    let total2 = 0
    data.data['female_map'].forEach((e) => {
      total2 = total2 + e.value
    })
    var option2 = {
      title: {
        text: '女生分布: ' + total2 + '人',
        subtext: data.ts,
        left: 'left',
      },
      tooltip: {
        trigger: 'item',
      },
      color: ['#41d619', '#6e6d6d'],
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data.data['female_map'],
          label: {
            show: true,
            formatter: (arg) => {
              return arg.data.name + ' ' + arg.data.value + '人 ' + arg.percent + '%'
            },
          },
        },
      ],
    }

    let total3 = 0
    data.data['male_map'].forEach((e) => {
      total3 = total3 + e.value
    })
    var option3 = {
      title: {
        text: '男生分布: ' + total3 + '人',
        subtext: data.ts,
        left: 'left',
      },
      tooltip: {
        trigger: 'item',
      },
      color: ['#41d619', '#6e6d6d'],
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data.data['male_map'],

          label: {
            show: true,
            formatter: (arg) => {
              return arg.data.name + ' ' + arg.data.value + '人 ' + arg.percent + '%'
            },
          },
        },
      ],
    }

    var option4 = {
      title: {
        text: '分区分布: ',
        subtext: data.ts,
        left: 'left',
      },
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data.data['ward_map'],

          label: {
            show: true,
            formatter: (arg) => {
              return arg.data.name + ' ' + arg.data.value + '人 ' + arg.percent + '%'
            },
          },
        },
      ],
    }

    var option6 = {
      title: {
        text: '域名分布: ',
        subtext: data.ts,
        left: 'left',
      },
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          data: data.data['domain_map'],

          label: {
            show: true,
            formatter: (arg) => {
              return arg.data.name + ' ' + arg.data.value + '人 ' + arg.percent + '%'
            },
          },
        },
      ],
    }
    return { option1, option2, option3, option4, option6 }
  }

  async function getNewUserChart() {
    const { code, data } = await getNewUserChartApi(14)
    console.log(data)
    if (code !== 200) {
      return
    }

    const option5 = {
      title: {
        text: '新用户分布',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: data.x,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          name: 'total',
          data: data.y_total,
          type: 'line',
          label: {
            show: true,
            position: 'top',
          },
          areaStyle: {},
        },
        {
          name: '已激活',
          data: data.y_active,
          type: 'line',
          areaStyle: {},
        },
      ],
    }
    return option5
  }

  async function handleChangeForceActive() {
    const newVal = !forceActive
    const { code, msg } = await setForceActiveApi(newVal)
    if (code !== 200) {
      Toast.show({ icon: 'fail', content: msg })
      return
    }
    setForceActive(newVal)
  }

  useEffect(() => {
    const initData = async () => {
      const { code, data } = await getOnlineUserMapApi()
      console.log(data)
      if (code !== 200) {
        navigate('/chat')
        return
      }
      const { option1, option2, option3, option4, option6 } = getOnlineUserMap(data)
      const echarts = await import('echarts')
      echarts.init(chartRef1.current).setOption(option1)
      echarts.init(chartRef2.current).setOption(option2)
      echarts.init(chartRef3.current).setOption(option3)
      echarts.init(chartRef4.current).setOption(option4)
      echarts.init(chartRef6.current).setOption(option6)

      const option5 = await getNewUserChart()
      echarts.init(chartRef5.current).setOption(option5)
    }
    initData()
  }, [])

  return (
    <div style={{ height: '100%', padding: '20px 10px 10px 10px', overflow: 'scroll' }}>
      <div style={{ margin: '0px 0px 10px 5px' }}>
        强制所有人激活：
        <Switch checked={forceActive} onChange={handleChangeForceActive} />
      </div>

      {/* <div style={{ margin: '10px 0px' }}>
        <Button
          color="primary"
          onClick={() => {
            navigate('/crm/check_bottle')
          }}>
          审核页面
        </Button>

        <Button
          style={{ marginLeft: 20 }}
          color="primary"
          onClick={() => {
            navigate('/crm/check_report')
          }}>
          举报页面
        </Button>
      </div> */}
      <div ref={chartRef1} style={{ width: '50%', height: '300px', display: 'inline-block' }} />
      <div ref={chartRef4} style={{ width: '50%', height: '300px', display: 'inline-block' }} />
      <div ref={chartRef2} style={{ width: '50%', height: '300px', display: 'inline-block' }} />
      <div ref={chartRef3} style={{ width: '50%', height: '300px', display: 'inline-block' }} />
      <div ref={chartRef6} style={{ width: '50%', height: '300px', display: 'inline-block' }} />

      <div ref={chartRef5} style={{ width: '100%', height: '300px', display: 'inline-block' }} />
    </div>
  )
}
