import { request, post } from './http'

export function getActiveExamRecordApi() {
  return post('/v1/active/get_exam_record', {})
}

export function updateExamRecordApi(input_answer, record_id, question_id) {
  return post('/v1/active/update_exam_record', {input_answer, record_id, question_id})
}

export function activeByExamApi() {
  return post('/v1/active/active_by_exam', {})
}

export function activeByWxApi(code) {
  return post('/v1/active/active_by_wx', {code})
}

export function activeByPhoneApi(phone, smsCode) {
  return post('/v1/active/active_by_phone', {phone, smsCode})
}

export function getActivePayUrlApi() {
  return post('/v1/active/get_active_pay_url', {})
}

export function getActivePayStatusApi(order_id) {
  return post('/v1/active/get_active_pay_status', {order_id})
}

export function loginByWxApi(code) {
  return post('/v1/user/login_by_wx', {code})
}

export function loginByPhoneApi(phone, smsCode) {
  return post('/v1/user/login_by_phone', {phone, smsCode})
}
