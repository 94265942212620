import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
// eslint-disable-next-line camelcase
import zh_CN from '@/locales/zh_CN.json';
import en from '@/locales/en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en,
      },
      zh_CN: {
        translation: zh_CN,
      }
    },
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;