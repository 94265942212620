export const SET_USER_INFO = 'SET_USER_INFO'
export const SET_USERS_INFO = 'SET_USERS_INFO'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'


export function setUserInfoCache(user) {  
  return {
    type: SET_USER_INFO,
    payload: user
  }
}

export function setUserMapMany(user_infos) {  
  return {
    type: SET_USERS_INFO,
    payload: user_infos
  }
}


export function updateUserInfoCache(uid, data) {  
  return {
    type: UPDATE_USER_INFO,
    payload: {uid, data}
  }
}
