export const SET_CHATLIST_QUERY = 'SET_CHATLIST_QUERY'
export const SET_MSGMAP_QUERY = 'SET_MSGMAP_QUERY'


export function setChatListQuery(fromId, hasMore) {
  return {
    type: SET_CHATLIST_QUERY,
    payload: {
      fromId,
      hasMore
    },
  }
}

export function setMsgMapQuery(chatid, fromId, hasMore) {
  return {
    type: SET_MSGMAP_QUERY,
    payload: {
      chatid,
      fromId,
      hasMore
    },
  }
}
