import { ADD_MSG, INSERT_MSGS, UPDATE_MSGS_STATUS, CLEAR_MSGS, DELETE_CHAT_MSGS, DELETE_MSG, SET_MSG_META, UPDATE_MSG_CONTENT } from '../actions/messageMap'

const initialState = {
  // c1: {
  //   m1: {
  //     id: 'm1',
  //     chatid: 'c1',
  //     uid: '',
  //     msgType: 'text',
  //     msgContent: 'hi',
  //     msgStatus: 'isSend',
  //     msgMeta
  //     ts: 1661107788000,
  //   },
  // },
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case ADD_MSG: {
      const messageId = payload.id
      if (!state[payload.chatid]) {
        state[payload.chatid] = {}
      }
      state[payload.chatid][messageId] = payload
      return Object.assign({}, state)
    }

    case INSERT_MSGS: {
      payload.forEach((e) => {
        if (!state[e.chatid]) {
          state[e.chatid] = {}
        }
        state[e.chatid][e.id] = e
      })
      return Object.assign({}, state)
    }

    case UPDATE_MSGS_STATUS: {
      const { chatid, messageIds, status } = payload
      messageIds.forEach((messageId) => {
        if (state[chatid] && state[chatid][messageId]) {
          state[chatid][messageId].msgStatus = status
        }
      })
      return Object.assign({}, state)
    }

    case CLEAR_MSGS: {
      const { chatids } = payload
      chatids.forEach(chatid => {
        if (state[chatid]) {
          delete state[chatid]
        }
      });
      return Object.assign({}, state)
    }

    case DELETE_CHAT_MSGS: {
      const { chatid } = payload
      if (state[chatid]) {
        delete state[chatid]
      }
      return Object.assign({}, state)
    }

    case DELETE_MSG: {
      const { chatid, messageId } = payload
      if (state[chatid] && state[chatid][messageId] ) {
        delete state[chatid][messageId]
      }
      return Object.assign({}, state)
    }
    
    case SET_MSG_META: {
      const {chatid, messageId, metaData} = payload
      if (state[chatid] && state[chatid][messageId] ) {
        const msgMeta = state[chatid][messageId].msgMeta
        state[chatid][messageId].msgMeta = {...msgMeta, ...metaData}
      }
      return Object.assign({}, state)
    }

    // 追加新的消息
    case UPDATE_MSG_CONTENT: {
      const {chatid, messageId, msgContent} = payload
      if (state[chatid] && state[chatid][messageId] ) {
        const oldMsgContent = state[chatid][messageId].msgContent
        state[chatid][messageId].msgContent = oldMsgContent + msgContent
      }
      return Object.assign({}, state)
    }
    
    default:
      return state
  }
}

export default reducer


