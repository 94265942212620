import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from '../reducers'
import reduxThunk from 'redux-thunk'
import thunk from 'redux-thunk'

// const store = createStore(rootReducer, applyMiddleware(thunk))

const middleware = [reduxThunk]

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(...middleware)
    // other store enhancers if any
  )
)

export default store
