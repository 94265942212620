import { PubSub } from 'pubsub-js'
import io from 'socket.io-client'
import cookie from 'react-cookies'

function guid() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0,
			v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

var browserId = localStorage.getItem('browserId');
if (!browserId) {
  browserId = guid()
  localStorage.setItem('browserId', browserId);
}
if (!cookie.load('bid')) {
  let cookieTime = new Date(new Date().getTime() + 365 * 24 * 3600 * 1000)
  cookie.save('bid', browserId, { expires: cookieTime })
}

let socket = null
let disconnectTs = null

function initWebSocket(uid, token) {
  socket = io('', {
    transports: ['websocket'],  // 只使用 WebSocket 传输方式
  }).connect()
  
  socket.on('connect', () => {
    wsLogin(uid, token)
    if (disconnectTs) {
      PubSub.publish('message', { func: 'load_new_messages', data: { disconnectTs: disconnectTs } })
      disconnectTs = new Date().getTime()
    }
    heartCheck.reset()
    heartCheck.start(uid, token)
  })

  socket.on('message', function (message) {
    let data = JSON.parse(message)
    PubSub.publish('message', data)
  })

  socket.on('disconnect', () => {
    heartCheck.reset()
    disconnectTs = new Date().getTime()
    PubSub.publish('message', { func: 'disconnect' })
  })
}

// 关闭连接
let closeWebSocket = () => {
  socket && socket.disconnect()
}

let heartCheck = {
  timeout: 8000, 
  timeoutObj: null,
  reset: function () {
    if (this.timeoutObj) {
      clearInterval(this.timeoutObj)
      return this
    }
  },
  start: function (uid, token) {
    this.timeoutObj = setInterval(function () {
      // 这里发送一个心跳，后端收到后，返回一个心跳消息，
      // onmessage拿到返回的心跳就说明连接正常
      console.log('heartCheck')
      socket.emit('heart_beat', uid, token)
    }, this.timeout)
  },
}

function wsLogin(uid, token) {
  socket.emit('login', uid, token, browserId)
}

function wsTyping(uid, toUid, chatid) {
  socket.emit('typing', uid, toUid, chatid)
}

function wsSendMsg(message_id, uid, to_uid, chatid, msgType, msgContent, msgMeta, is_group_chat, force_green) {
  const params = {
    message_id,
    uid,
    to_uid,
    chatid,
    msg: {
      msg_type: msgType,
      msg_content: msgContent,
      msg_meta: msgMeta,
    },
    is_group_chat,
    force_green
  }
  socket.emit('send_msg', params)
}

function wsSetMsgsIsRead(uid, to_uid, chatid, message_ids) {
  // 和客服聊天看不到是否已读
  if (uid === '635bb18fe70cf88f55e04368') return
  socket.emit('set_msgs_is_read', uid, to_uid, chatid, message_ids)
}

function wsDeleteMsg(uid, message_uid, message_id, chatid, to_uid, is_group_chat) {
  socket.emit('delete_msg', uid, message_uid, message_id, chatid, to_uid, is_group_chat)
}

function wsJoinRoom(uid, group_chat_id) {
  socket.emit('join_room', uid, group_chat_id)
}

function wsLeaveRoom(uid, group_chat_id) {
  socket.emit('leave_room', uid, group_chat_id)
}

function wsStartMatch(uid, settings) {
  socket.emit('start_match', uid, settings)
}

function wsQuitMatch(uid) {
  socket.emit('quit_match', uid)
}

function wsSetChatIsRead(uid, to_uid, chatid) {
  // 和客服聊天看不到是否已读
  if (uid === '635bb18fe70cf88f55e04368') return
  socket.emit('set_chat_is_read', uid, to_uid, chatid)
}

function wsSetMsgMeta(to_uid, chatid, message_id, meta_data) {
  socket.emit('set_msg_meta', to_uid, chatid, message_id, meta_data)
}

export { initWebSocket, closeWebSocket, wsTyping, wsSendMsg, wsSetMsgsIsRead, wsDeleteMsg, wsJoinRoom, wsLeaveRoom, wsStartMatch, wsQuitMatch, wsSetChatIsRead, wsSetMsgMeta }
