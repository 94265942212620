export const LOAD_MORE = 'LOAD_MORE'
export const SET_LAST_MSG = 'SET_LAST_MSG'
export const ADD_CHAT = 'ADD_CHAT'
export const CHANGE_UNREAD_MSG_COUNT = 'CHANGE_UNREAD_MSG_COUNT'
export const DELETE_CHAT = 'DELETE_CHAT'
export const CLEAR_CHATS = 'CLEAR_CHATS'
export const UPDATE_CHAT = 'UPDATE_CHAT'
export const SET_GROUP_UIDS = 'SET_GROUP_UIDS'
export const OTHER_DELETE_CHAT = 'OTHER_DELETE_CHAT'


export function loadMoreChats(data) {
  let chats = []
  data.forEach(e => {
    e.last_msg_type = e.last_msg ? e.last_msg.msg_type: null
    e.last_msg_content = e.last_msg ? e.last_msg.msg_content: null
    e.last_msg_date =e.last_msg ? e.last_msg.ts: null
    chats.push(e)
  });
  return {
    type: LOAD_MORE,
    payload: chats,
  }
}


export function setLastMsg(chatid, lastMsgType, lastMsgContent, lastMsgDate) {  
  return {
    type: SET_LAST_MSG,
    payload: {chatid, lastMsgType, lastMsgContent, lastMsgDate}
  }
}


export function addChat(chat) {  
  chat.last_msg_type = chat.last_msg ? chat.last_msg.msg_type: null
  chat.last_msg_content = chat.last_msg ? chat.last_msg.msg_content: null
  chat.last_msg_date = chat.last_msg ? chat.last_msg.ts: null
  return {
    type: ADD_CHAT,
    payload: chat
  }
}

export function changeUnreadCount(chatid, count, isCover) {  

  return {
    type: CHANGE_UNREAD_MSG_COUNT,
    payload: { chatid, count, isCover }
  }
}


export function deleteChat(chatid) {  

  return {
    type: DELETE_CHAT,
    payload: { chatid }
  }
}

export function otherDeleteChat(chatid) {  

  return {
    type: OTHER_DELETE_CHAT,
    payload: { chatid }
  }
}

export function updateChat(chatid, data) {  

  return {
    type: UPDATE_CHAT,
    payload: { chatid, data }
  }
}



export function clearChats(chatids) {  

  return {
    type: CLEAR_CHATS,
    payload: { chatids }
  }
}

export function setGroupUids(chatid, uids, opt, uidType) {  

  return {
    type: SET_GROUP_UIDS,
    payload: { chatid, uids, opt, uidType }
  }
}


