import { post } from './http'

export function getCurrUserApi(is_debug) {
  return post('/v1/user/get_info', {is_debug})
}

export function updateUserApi(updateData) {
  return post('/v1/user/update_info', updateData)
}

export function registerApi(username, password) {
  return post('/v1/user/register', { username, password })
}

export function LoginApi(username, password) {
  return post('/v1/user/login', { username, password })
}

export function resetPasswordApi(password) {
  return post('/v1/user/reset_password', { password })
}

export function getNicknameApi() {
  return post('/v1/user/generate_nickname', {  })
}

export function getUserBaseInfoApi(uid, from) {
  return post('/v1/user/get_user_base_info', { uid, from })
}

export function getUsersBaseInfoApi(uids) {
  return post('/v1/user/get_users_base_info', { uids })
}

export function logoutApi() {
  return post('/v1/user/logout', { })
}

export function getCallmeTokenApi() {
  return post('/v1/user/get_callme_token', { })
}

export function generateCallmeTokenApi() {
  return post('/v1/user/generate_callme_token', { })
}

export function deleteCallmeTokenApi() {
  return post('/v1/user/delete_callme_token', { })
}

export function getCallmeUserApi(callme_token) {
  return post('/v1/user/get_callme_user', {callme_token })
}

export function setRemarkNameApi(to_uid, remark_name) {
  return post('/v1/user/set_remark_name', {to_uid, remark_name})
}

export function getUserLikeLimitApi(to_uid, chatid) {
  return post('/v1/user/get_user_like_limit', {to_uid, chatid})
}

export function addUserLikesApi(to_uid, chatid, likes_count) {
  return post('/v1/user/add_user_likes', {to_uid, chatid, likes_count})
}

export function setUserBlockApi(to_uid, chatid, is_block) {
  return post('/v1/user/set_block', {to_uid, chatid, is_block})
}

export function reportBlockUserApi(to_uid, chatid, reason) {
  return post('/v1/user/report_block_user', {to_uid, chatid, reason})
}

export function getAdApi() {
  return post('/v1/user/get_ad', {})
}

export function newUserApi(gender, age, verify_code, verify_code_id, bindToken) {
  return post('/v1/user/new', {gender, age, verify_code, verify_code_id, bindToken})
}

export function getBannedUsersApi() {
  return post('/v1/user/get_banned_users', {})
}

export function destroyUsersApi() {
  return post('/v1/user/destroy', {})
}

export function sendSMSApi(phone, target, verifyCode, verifyCodeId) {
  return post('/v1/user/send_sms', {phone, target, verifyCode, verifyCodeId})
}

