import { SET_CHATLIST_QUERY, SET_MSGMAP_QUERY } from "../actions/app"

const initialState = {
  chatListQuery_hasMore: false,
  chatListQuery_fromId: null,
  // TODO: 限制最多200个聊天  
  chatListQuery_limit: 200,

  msgMapQuery: {},
  msgMapQuery_limit: 40,
}

const reducer  =   (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {

    case SET_CHATLIST_QUERY: {
      state.chatListQuery_fromId = payload.fromId
      state.chatListQuery_hasMore = payload.hasMore
      return Object.assign({}, state)
    }

    case SET_MSGMAP_QUERY: {
      const {chatid, fromId, hasMore} = payload
      state.msgMapQuery[chatid] = {fromId, hasMore}
      return Object.assign({}, state)
    }

    default:
      return state
  }
}


export default reducer