import { SET_USER_INFO, UPDATE_USER_INFO, SET_USERS_INFO } from "../actions/userMap"

const initialState = {
  // 'u1': {
    // id: null,
    // nickname: '',
    // avatar: null,
    // gender: 'keep_secret',
    // age: 0,
    // location: null,
    // show_location: false,
    // account: null,
    // status: null,
    // remark_name: null,
    // likes: null,
  // }, 
}

const userMapReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SET_USER_INFO: {
      const user = payload
      state[user.id] = user      
      return Object.assign({}, state)
    }    

    case SET_USERS_INFO: {
      const user_infos = payload
      user_infos.forEach(e => {
        const uid = e.id
        state[uid] = e
      });
      return Object.assign({}, state)
    }

    case UPDATE_USER_INFO: {
      const {uid, data}  = payload
      state[uid] = Object.assign({}, state[uid]||{}, data) 
      return Object.assign({}, state)
    }  

    default:
      return state
  }
}

export default userMapReducer
