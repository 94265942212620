import { post } from './http'

export function getProductsApi() {
  return post('/v1/order/get_products', {})
}

export function getPayUrlApi(title) {
  return post('/v1/order/get_pay_url', {title})
}

export function getWaitOrdersApi() {
  return post('/v1/order/get_wait_orders', {})
}

export function cancelOrderApi(order_id) {
  return post('/v1/order/cancel_order', {order_id})
}

export function getOrderStatusApi(order_id) {
  return post('/v1/order/get_order_status', {order_id})
}



