import axios from 'axios'
import CryptoJS from 'crypto-js';

function createSignature(timestamp) {
  const secret = "aec64f090ae7ded49d4767c7ac2919f6"
  const baseString = `${timestamp}${secret}`;
  const sign = CryptoJS.MD5(baseString).toString();
  return sign
}

axios.defaults.withCredentials = true

// axios 配置
var instance = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 30000,
  baseURL: '/api/',
})

// 可以在这先申明错误代码表示的含义

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const timestamp = Date.now();
    const signature = createSignature(timestamp);
    config.headers['Authroization'] = signature;
    config.headers['X-FU'] = timestamp;
    return config
  },
  (error) => {
    // 对请求错误做些什么
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const rsp = response.data
    if (rsp.code !== 200) {
      console.log(response.config.url, rsp)
    }
    return rsp
  },
  (error) => {
    console.log(error)
    return Promise.reject(error)
  }
)

export default instance

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance.post(url, data).then(
      (response) => {
        // 对接口错误码做处理
        resolve(response)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

/**
 * get 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
function get(url, data = {}) {
  return new Promise((resolve, reject) => {
    instance
      .get(url, {
        params: data,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * 封装所有请求
 * @param method
 * @param url
 * @param data
 * @param headers
 * @returns {Promise}
 */
function request(method, url, data = {}, headers) {
  return new Promise((resolve, reject) => {
    instance({
      method: method || 'post',
      url: url,
      data:
        method === 'get'
          ? {
              params: data,
            }
          : data,
      headers: headers || {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // 对接口错误码做处理
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export { get, post, request }
