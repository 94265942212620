import { post } from './http'

export function getOnlineUserMapApi() {
  return post('/v1/crm/get_online_user_map', {})
}

export function setForceActiveApi(force_active) {
  return post('/v1/crm/set_force_active', {force_active})
}

export function getNewUserChartApi(days_ago) {
  return post('/v1/crm/get_new_user_chart', {days_ago})
}

export function debugUserApi(uid, code) {
  return post('/v1/crm/debug', {uid, code})
}

export function getDebugUrlApi(to_uid) {
  return post('/v1/crm/get_debug_url', {to_uid})
}

export function updateUserLikesApi(to_uid, add_likes, remark) {
  return post('/v1/crm/update_user_likes', {to_uid, add_likes, remark})
}

export function setVipOrderApi(to_uid, pay_type, pay_fee_cent, cycle_time, remark) {
  return post('/v1/crm/set_vip_order', {to_uid, pay_type, pay_fee_cent, cycle_time, remark})
}

export function getWaitConfirmBottlesApi() {
  return post('/v1/crm/get_wait_confirm_bottles', {})
}

export function setBottleConfirmStatusApi(bottle_id, status) {
  return post('/v1/crm/set_bottle_confirm_status', {bottle_id, status})
}

export function getWaitConfirmReportsApi() {
  return post('/v1/crm/get_wait_confirm_reports', {})
}

export function setReportConfirmStatusApi(report_id, status, to_uid, is_permanent, reason, uid) {
  return post('/v1/crm/set_report_confirm_status', {report_id, status, to_uid, is_permanent, reason, uid})
}